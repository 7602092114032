<template>
    <div>
        <div class="row">
            <div class="col-xl-12">
                <b-card>
                    <b-row>
                        <b-col sm="4" xl="3" md="3">
                            <label>*Resource</label>
                            <v-select v-model="RESOURCE" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="LABEL" :options="RESOURCE_STORE" class="select-size-sm" />
                        </b-col>

                        <b-col sm="4" xl="3" md="3">
                            <label>*Balai</label>
                            <v-select v-model="BALAI" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="LABEL" :options="BALAI_STORE" class="select-size-sm" />
                        </b-col>

                        <b-col sm="4" xl="3" md="3">
                            <label>Provinsi</label>
                            <v-select v-model="PROVINSI" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="LABEL" :options="PROVINSI_STORE" class="select-size-sm" />
                        </b-col>

                        <!-- <b-col sm="4" xl="3" md="2">
              <label>Kota/Kabupaten</label>
              <v-select v-model="KOTAKABUPATEN" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="LABEL" :options="KOTAKABUPATEN_STORE" class="select-size-sm" />
            </b-col> -->

                        <b-col sm="4" xl="2" md="3">
                            <!-- <label>Tanggal</label>
              <a-date-picker @change="onChange" :disabledDate="disabledDate" /> -->
                            <b-form-group label="Tanggal" label-for="tanggal">
                                <a-date-picker class="w-100" id="tanggal" @change="onChange" :disabledDate="disabledDate" />
                            </b-form-group>
                        </b-col>

                        <b-col sm="4 mt-2 pad2" xl="1" md="1">
                            <b-form-group>
                                <b-form inline id="select-form">
                                    <b-button v-on:click="onFetch" variant="primary" class="ml-sm-6" size="">Filter</b-button>
                                </b-form>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card>
            </div>
        </div>
        <a-spin :spinning="isLoading">
            <div class="row">
                <div class="col-xl-12">
                    <b-card>
                        <b-row v-if="showTable">
                            <div v-if="RESOURCE_SELECTED != 2 && RESOURCE_SELECTED != 3" style="width: 100%; overflow: auto;">
                                <GtsDinamicTable
                                    :PERIODE="DATE_SELECTED"
                                    :RESOURCE="RESOURCE"
                                    :BALAI="BALAI"
                                    :DATAS="RAW_DATA_TABLE"
                                    :ExportExcelName="ExportExcelName"
                                    @selectionChanged="selectionChanged"
                                    @onRowClick="onRowClick"
                                    ref="dinamicTableComponent"
                                >
                                </GtsDinamicTable>
                            </div>
                            <div v-else style="width: 100%; overflow: auto;">
                                <gts-pibal-table
                                    :PERIODE="DATE_RANGE_SELECTED"
                                    :RESOURCE="RESOURCE"
                                    :BALAI="BALAI"
                                    :DATAS="RAW_DATA_TABLE"
                                    :ExportExcelName="ExportExcelName"
                                    @selectionChanged="selectionChanged"
                                    @onRowClick="onRowClick"
                                    ref="dinamicTableComponent"
                                />
                            </div>
                        </b-row>
                    </b-card>
                </div>
            </div>
        </a-spin>
    </div>
</template>

<script>
import vSelect from "vue-select";

import GtsDinamicTable from "../GtsDinamicTable.vue";
import GtsPibalTable from "./mtbcomponent/GtsPibalTable.vue";

import {
    BOverlay,
    BTable,
    BAvatar,
    BBadge,
    BPagination,
    BInputGroupAppend,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormInput,
    BInputGroup,
    BFormSelect,
    BButton,
    BButtonGroup,
    BFormDatepicker,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormGroup,
    BFormTextarea,
    BPopover,
    BModal,
    VBModal,
    BForm,
    AlertPlugin,
} from "bootstrap-vue";

import metadata from "@/api/metadata";
import monitoring from "@/api/monitoring";
import moment from "moment";

export default {
    components: {
        GtsPibalTable,
        GtsDinamicTable,
        vSelect,
        BOverlay,
        BTable,
        BAvatar,
        BBadge,
        BPagination,
        BInputGroupAppend,
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardTitle,
        BFormInput,
        BInputGroup,
        BFormSelect,
        BButton,
        BButtonGroup,
        BFormDatepicker,
        BFormCheckboxGroup,
        BFormCheckbox,
        BFormGroup,
        BFormTextarea,
        BPopover,
        BModal,
        VBModal,
        BForm,
        AlertPlugin,
    },
    data() {
        return {
            ExportExcelName: "BMKGSatu Monitoring",
            isLoading: false,
            showTable: false,
            BALAI: "",
            BALAI_STORE: [],
            PROVINSI: "",
            PROVINSI_STORE: [],
            KOTAKABUPATEN: "",
            KOTAKABUPATEN_STORE: [],
            RESOURCE: "",
            RESOURCE_STORE: [
                { KEY: "1", LABEL: "SINOPTIK", CODE: "SINOPTIK" },
                { KEY: "2", LABEL: "PILOT", CODE: "PILOT" },
                { KEY: "3", LABEL: "TEMP", CODE: "TEMP" },
                { KEY: "4", LABEL: "METAR", CODE: "METAR" },
                { KEY: "5", LABEL: "SPECI", CODE: "SPECI" },
                { KEY: "6", LABEL: "TAF", CODE: "TAF" },
                { KEY: "7", LABEL: "BERITA CLIMAT", CODE: "CLIMAT" },
                { KEY: "8", LABEL: "WXREV", CODE: "WXREV" },
                {
                    KEY: "9",
                    LABEL: "CLIMAT TEMP",
                    CODE: "CLIMAT_TEMP",
                    disabled: true,
                },
                { KEY: "10", LABEL: "SEISMIC DATA", CODE: "SEISMIC_DATA" },
                { KEY: "11", LABEL: "WARNING", CODE: "WARNING" },
                { KEY: "11", LABEL: "CROSSWIND", CODE: "CROSSWIND" },
            ],

            BALAI_SELECTED: "",
            PROVINSI_SELECTED: "",
            KOTAKABUPATEN_SELECTED: "",
            RESOURCE_SELECTED: "",
            DATE_SELECTED: "",
            RAW_DATA_TABLE: [],
            pageOptions: [5, 10, 20, 50],
            totalRows: 1,
            currentPage: 1,
            filter: null,
            filterOn: [],
            fields: [],
            filter_data: "",
            perPage: 10,
            columns: [],
            rows: [],
            DATA_FLAG: [
                "00:00",
                "01:00",
                "02:00",
                "03:00",
                "04:00",
                "05:00",
                "06:00",
                "07:00",
                "08:00",
                "09:00",
                "10:00",
                "11:00",
                "12:00",
                "13:00",
                "14:00",
                "15:00",
                "16:00",
                "17:00",
                "18:00",
                "19:00",
                "20:00",
                "21:00",
                "22:00",
                "23:00",
                "00:30",
                "01:30",
                "02:30",
                "03:30",
                "04:30",
                "05:30",
                "06:30",
                "07:30",
                "08:30",
                "09:30",
                "10:30",
                "11:30",
                "12:30",
                "13:30",
                "14:30",
                "15:30",
                "16:30",
                "17:30",
                "18:30",
                "19:30",
                "20:30",
                "21:30",
                "22:30",
                "23:30",
                "01",
                "02",
                "03",
                "04",
                "05",
                "06",
                "07",
                "08",
                "09",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "18",
                "19",
                "20",
                "21",
                "22",
                "23",
                "24",
                "25",
                "26",
                "27",
                "28",
                "29",
                "30",
                "31",
            ],
            ROW_HIDE: ["region", "propinsi", "kabupaten", "current_latitude", "current_longitude", "count_available", "count_not_available"],
        };
    },
    mounted() {
        this.optionRegion();
    },
    watch: {
        RESOURCE(VALUES) {
            this.RESOURCE_SELECTED = VALUES ? VALUES.KEY : "";
        },
        BALAI(VALUES) {
            this.BALAI_SELECTED = VALUES ? VALUES.KEY : "";
            if (VALUES) {
                this.listPropinsi(VALUES.KEY);
            }

            this.PROVINSI = [
                {
                    KEY: "",
                    LABEL: "PILIH PROVINSI",
                },
            ];
            this.PROVINSI_SELECTED = "";
        },
        PROVINSI(VALUES) {
            this.PROVINSI_SELECTED = VALUES ? VALUES.KEY : "";
            if (VALUES) {
                this.listKabupaten(VALUES.KEY);
            }
            // this.KOTAKABUPATEN = [
            //   {
            //     KEY: "",
            //     LABEL: "PILIH KOTA/KABUPATEN",
            //   },
            // ];
            // this.KOTAKABUPATEN_SELECTED = "";
        },
        KOTAKABUPATEN(VALUES) {
            this.KOTAKABUPATEN_SELECTED = VALUES.KEY;
        },
    },
    methods: {
        moment,
        disabledDate(current) {
            // Can not select days before today and today
            return current && current > moment().endOf("day");
        },
        onChange(date, dateString) {
            this.DATE_SELECTED = dateString;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        onFetch() {
            if (!this.RESOURCE_SELECTED || this.RESOURCE_SELECTED == "" || !this.BALAI_SELECTED || this.BALAI_SELECTED == "" || !this.DATE_SELECTED || this.DATE_SELECTED == "") {
                this.$swal({
                    title: "ERROR",
                    text: "Silahkan Lengkapi Filter",
                    icon: "error",
                    customClass: {
                        confirmButton: "btn btn-danger",
                    },
                    buttonsStyling: false,
                });
                return;
            }
            if (this.RESOURCE_SELECTED == 21 || this.RESOURCE_SELECTED == "21") {
                this.ROW_HIDE = [
                    "region",
                    "propinsi",
                    "kabupaten",
                    "current_latitude",
                    "current_longitude",
                    "count_available",
                    "count_not_available",
                    "01:00",
                    "02:00",
                    "03:00",
                    "04:00",
                    "05:00",
                    "07:00",
                    "08:00",
                    "09:00",
                    "10:00",
                    "11:00",
                    "13:00",
                    "14:00",
                    "15:00",
                    "16:00",
                    "17:00",
                    "19:00",
                    "20:00",
                    "21:00",
                    "22:00",
                    "23:00",
                    "24:00",
                ];
            } else {
                this.ROW_HIDE = ["region", "propinsi", "jam_pengamatan", "kabupaten", "current_latitude", "current_longitude", "count_available", "count_not_available"];
            }
            this.getMonitoring();
        },
        async getMonitoring() {
            this.isLoading = true;
            this.showTable = false;
            this.ExportExcelName = "BMKGSatu Monitoring GTS_" + this.RESOURCE.CODE + "_" + this.DATE_SELECTED;
            try {
                const { data } = await monitoring.getMonitoringGts(this.RESOURCE_SELECTED, this.BALAI_SELECTED, this.PROVINSI_SELECTED, this.DATE_SELECTED);
                // console.log("datas : ", data);
                var datas = data;
                var cols = [];
                var raws = [];
                var rawsXls = [];
                var colAvaliable = [];
                // var ROW_FLAG = this.DATA_FLAG;
                if (this.RESOURCE_SELECTED != 2 && this.RESOURCE_SELECTED != 3) {
                    for (var i in datas) {
                        var col = datas[i];
                        // for (var iCol in col) {
                        //     if (col[iCol].key == "progress") {
                        //         col[iCol].index = col.length;
                        //     }
                        // }

                        var colsShort = col.sort(function (a, b) {
                            return Number(a.index) - Number(b.index);
                        });
                        var rawSet = {};
                        var rawSetXls = {};
                        for (var iCS in colsShort) {
                            console.log("iCS", colsShort[iCS]);
                            var infoColShort = colsShort[iCS];
                            if (this.ROW_HIDE.indexOf(infoColShort.key) != -1) {
                                continue;
                            }
                            if (colAvaliable.indexOf(infoColShort.key) == -1) {
                                if (infoColShort.key != "jam_pengamatan") {
                                    colAvaliable.push(infoColShort.key);
                                    var colsSend = {};
                                    colsSend["label"] = infoColShort.label;
                                    colsSend["field"] = infoColShort.key;
                                    colsSend["renderFlag"] = false;
                                    // ROW_FLAG.indexOf(infoColShort.key) != -1
                                    //     ? true
                                    //     : false;
                                    cols.push(colsSend);
                                }
                            }
                            if (rawSet[infoColShort.key] == undefined) {
                                rawSet[infoColShort.key] = "";
                            }
                            if (rawSetXls[infoColShort.key] == undefined) {
                                rawSetXls[infoColShort.key] = "";
                            }
                            // rawSet[infoColShort.key] = infoColShort.value;
                            rawSet[infoColShort.key] = [infoColShort.value, infoColShort.status];
                            rawSetXls[infoColShort.key] = infoColShort.value;

                            if (infoColShort.hasOwnProperty("periode")) {
                                rawSetXls["periode"] = infoColShort.periode;
                            }
                        }
                        raws.push(rawSet);
                        rawsXls.push(rawSetXls);
                    }
                } else {
                    for (var i in datas) {
                        var col = datas[i];

                        var colsShort = col.sort(function (a, b) {
                            return Number(a.index) - Number(b.index);
                        });
                        var rawSet = {};
                        var rawSetXls = {};
                        for (var iCS in colsShort) {
                            // console.log("iCS", colsShort[iCS]);
                            var infoColShort = colsShort[iCS];
                            if (this.ROW_HIDE.indexOf(infoColShort.key) != -1) {
                                continue;
                            }
                            if (colAvaliable.indexOf(infoColShort.key) == -1) {
                                if (infoColShort.key != "jam_pengamatan" && infoColShort.key != undefined) {
                                    colAvaliable.push(infoColShort.key);
                                    var colsSend = {};
                                    let label = infoColShort.label;
                                    let key = infoColShort.key;
                                    let arrKey = label.split(" ");
                                    if (arrKey && arrKey.length > 1) {
                                        label = arrKey[0].indexOf("00") > -1 ? arrKey[1] : label;
                                        key = arrKey.length > 1 ? arrKey[1] : key;
                                    }
                                    colsSend["label"] = label; //infoColShort.label;
                                    colsSend["key"] = infoColShort.key;
                                    colsSend["class"] = "my-left-border";
                                    colsSend["renderFlag"] = false;
                                    // ROW_FLAG.indexOf(infoColShort.key) != -1
                                    //     ? true
                                    //     : false;
                                    cols.push(colsSend);
                                }
                            }
                            if (rawSet[infoColShort.key] == undefined) {
                                // rawSet[infoColShort.key] = "";
                            }
                            if (rawSetXls[infoColShort.key] == undefined) {
                                rawSetXls[infoColShort.key] = "";
                            }
                            // rawSet[infoColShort.key] = infoColShort.value;
                            if (infoColShort.key != undefined) {
                                rawSet[infoColShort.key] = [infoColShort.value, infoColShort.status];
                            }
                            rawSetXls[infoColShort.key] = infoColShort.value;

                            if (infoColShort.hasOwnProperty("periode")) {
                                rawSetXls["periode"] = infoColShort.periode;
                            }
                        }
                        raws.push(rawSet);
                        rawsXls.push(rawSetXls);
                    }
                }

                var dataBuild = { DATAS: raws, columns: cols, XLSData: rawsXls };
                console.log("dataBuild", dataBuild);
                this.RAW_DATA_TABLE = dataBuild;

                this.showTable = true;

                setTimeout(() => {
                    this.$refs.dinamicTableComponent.generateTable(this.RAW_DATA_TABLE);
                    this.isLoading = false;
                }, 1000);
            } catch (e) {
                this.isLoading = false;
                console.log(e);
            }
        },
        sortDataTable() {
            var data = this.RAW_DATA_TABLE;
            var TempRaw = {};
            for (var i in data) {
                var items = data[i];
            }
        },
        async optionRegion() {
            try {
                const { data } = await metadata.getRegionList();
                // console.log(data);
                for (var i = 0; i < data.length; i++) {
                    const dataRegion = {
                        KEY: data[i].region_id,
                        LABEL: data[i].region_description,
                    };
                    this.BALAI_STORE.push(dataRegion);
                }
                // this.BALAI = 1
            } catch (e) {
                console.log(e);
            }
        },
        async listPropinsi(region_id) {
            this.PROVINSI_STORE = [];
            try {
                const { data } = await metadata.getPropinsiListByRegion(region_id);
                var _selected = "";
                for (var i = 0; i < data.length; i++) {
                    const rowsPropinsi = {
                        KEY: data[i].propinsi_id,
                        LABEL: data[i].propinsi_name,
                    };
                    this.PROVINSI_STORE.push(rowsPropinsi);
                    _selected = data[i].propinsi_id;
                }
                // this.PROVINSI" = _selected;
            } catch (e) {
                console.log(e);
            }
        },
        async listKabupaten(propinsi_id) {
            this.KOTAKABUPATEN_STORE = [];
            try {
                const { data } = await metadata.getKabupatenListByPropinsi(propinsi_id);
                var _selected = "";
                for (var i = 0; i < data.length; i++) {
                    const rowsKabupaten = {
                        KEY: data[i].kabupaten_id,
                        LABEL: data[i].kabupaten_name,
                    };
                    this.KOTAKABUPATEN_STORE.push(rowsKabupaten);
                    _selected = data[i].kabupaten_id;
                }
                // this.KOTAKABUPATEN = _selected;
            } catch (e) {
                console.log(e);
            }
        },
        onRowClick(params) {
            // console.log(params);
        },
        selectionChanged(params) {
            console.log(params);
        },
    },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
<style>
.header-box-custome {
    background: #3f9cff;
    padding: 10px;
    border-radius: 5px;
    color: #ffffff;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 10px;
}

.monitor .vgt-table {
    font-size: 12px !important;
}

[dir="ltr"] .monitor .vgt-table th {
    padding: 5px 25px 5px 5px !important;
}

[dir] .monitor table.vgt-table td {
    padding: 5px 25px 5px 5px !important;
}
</style>
